import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Bg from "./Bg";
import "./BgSlider.css";


import image1 from "./Image/2.jpg";
import image2 from "./Image/1.jpg";
import image3 from "./Image/3.jpg";
import image4 from "./Image/5.jpg";
import image5 from "./Image/4.jpg";

export default class Fade extends Component {
  render() {
    const settings = {
      dots: true,
      fade: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      speed: 8000,
      autoplaySpeed: 10000,
      cssEase: "linear",
      pauseOnHover: false
    
    };
    return (
      <div className="slides">
        
        <Slider {...settings}>
            <Bg image={image1} />
            <Bg image={image2} />
            <Bg image={image3} />
            <Bg image={image4} />
            <Bg image={image5} />
        
        </Slider>
      </div>
    );
  }
}