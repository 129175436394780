import classes from "./Header.module.css";
import fb from "../FOOTER/Images/facebook.png";
import insta from "../FOOTER/Images/instagram.png";
import linkdin from "../FOOTER/Images/linkedin.png";
import { Link } from "react-router-dom";


function Header(){
    return(
    <div className={classes.container}>
        <p>Customer Support (24/7) : <a href="tel:+91 7030990011">+91 7030990011 </a> | <a href="tel:+91 9960415779">+91 9960415779 </a></p>
        <p>Email: mydreamsholidays2@gmail.com</p>
        <p className={classes.icons}><span className={classes.icon}><a href="https://www.facebook.com/mydreamsholiday/" target="_"><img src={fb} alt="tours and travels in pune" /></a></span>
           <span className={classes.icon}><a href="https://www.instagram.com/mydreamsholidays2/" target="_" ><img src={insta} alt="tours and travels in pune" /></a></span>
           <span className={classes.icon}><a href="https://www.linkedin.com/in/my-dreams-holidays-5952b6298/" target="_"><img src={linkdin} alt="tours and travels in pune" /></a></span>
        </p>
    </div>
    );
};
export default Header;