import classes from "./Testimonials.module.css";
import TestimonialsSlider from "./TestimonialsSlider";

function Testimonials (){
    return(
       <div className={classes.container}>
           <div className={classes.left}>
            <p className={classes.orange}>Testimonials</p>
            <h2>Our Esteemed <span className={classes.blue}>Customers</span> Experience</h2>
            <TestimonialsSlider />
           </div>
           <div className={classes.right}>
            <p className={classes.orange}>We are not just a name</p>
            <h2>We are a <span className={classes.blue}>Brand</span> since <span className={classes.blue}>2001</span></h2>
            <div className={classes.box}>
               <div className={classes.r1}>
                <h1>150+</h1>
                <p>Amazing Tours</p>
               </div> 
               <div className={classes.r2}>
                <h1>500000+</h1>
                <p>Families</p>
               </div> 
            </div>
            <div className={classes.box}>
               <div className={classes.r1}>
                <h1>50+</h1>
                <p>Years of Experience</p>
               </div> 
               <div className={classes.r2}>
                <h1>1500+</h1>
                <p>Corporates</p>
               </div> 
            </div>
           </div>
       </div>
    );
};
export default Testimonials;