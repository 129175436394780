import { Link } from "react-router-dom";
import  "./Navbar.css";
import LOGO from "./Images/LOGO.png";
import { useState } from "react";

const Navbar = () =>{
   const [click , setClick] =useState(false);
   
   const burger =()=>{
    setClick(!click)
   }
    return(
    <nav className="container">
          <div className="logo">
            <img src={LOGO} alt="MY DREAMS HOLIDAY" />
          </div>
          <div className="mobile" onClick={burger}>
            <i className={click ? "fas fa-times" : "fas fa-bars" }></i>
          </div>
          <div className={click ? "navLink2 active" : "navLink"}>
            <ul >
              <li><Link className="lnks" to="/">Home</Link></li>
              <li><Link className="lnks" to="/about">About Us</Link></li>
              <li><Link className="lnks" to="/services">Services</Link></li>
              <li><Link className="lnks" to="/contact">Contact Us</Link></li>
            </ul>
            
          </div>
    </nav>
    );
};
export default Navbar;