import { Link } from "react-router-dom";
import classes from "./MdhCard.module.css";


function MdhCard (){
    return(
        <div className={classes.container}>
        
        <div className={classes.card1}>
           <h2>AMAZING HOLIDAY</h2>
           
           <button><Link to="/contact" className={classes.btnlnk}>Enquire Now</Link></button>
        </div>
        <div className={classes.card2}>
           <h2>HONEYMOON PACKAGES</h2>
           
           <button><Link to="/contact" className={classes.btnlnk}>Enquire Now</Link></button>
        </div>
        <div className={classes.card3}>
           <h2>BUY 1 GET 1 FREE INTERNATIONAL PACKAGES</h2>
           
           <button><Link to="/contact" className={classes.btnlnk}>Enquire Now</Link></button>
        </div>
        <div className={classes.card4}>
           <h2>SPIRITUAL TOUR PACKAGES</h2>
           
           <button><Link to="/contact" className={classes.btnlnk}>Enquire Now</Link></button>
        </div>
        
        
      </div>
    );
};
export default MdhCard;