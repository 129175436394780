import React, { Component } from "react";
import Slider from "react-slick";
import classes from "./Testimonials.module.css";

export default class VerticalMode extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      vertical: true,
      autoplay: true,
      speed: 2000,
      pauseOnHover: true,
      autoplaySpeed: 4000,
      cssEase: "linear",
      verticalSwiping: true,
      beforeChange: function(currentSlide, nextSlide) {
        console.log("before change", currentSlide, nextSlide);
      },
      afterChange: function(currentSlide) {
        console.log("after change", currentSlide);
      }
    };
    return (
      <div className={classes.over}>
        <Slider {...settings}>
        <div className={classes.card}>
                <p>Recently back from Kerala, where Riya Team helped me plan a fantastic tour. I now have a good sense of this beautiful state. They made sure I was well looked after, all connections were made safely and accommodation and activities were best suited to my needs. I hope to return someday and if I do, I know who to call!</p>
                <h4>Mr. Shetty</h4>
        </div>
        <div className={classes.card}>
                <p>Recently back from Kerala, where Riya Team helped me plan a fantastic tour. I now have a good sense of this beautiful state. They made sure I was well looked after, all connections were made safely and accommodation and activities were best suited to my needs. I hope to return someday and if I do, I know who to call!</p>
                <h4>Mr. Shetty</h4>
        </div>
        <div className={classes.card}>
                <p>Recently back from Kerala, where Riya Team helped me plan a fantastic tour. I now have a good sense of this beautiful state. They made sure I was well looked after, all connections were made safely and accommodation and activities were best suited to my needs. I hope to return someday and if I do, I know who to call!</p>
                <h4>Mr. Shetty</h4>
        </div>
        </Slider>
      </div>
    );
  }
}