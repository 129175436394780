import classes from "./CarRental.module.css";
import car from "./Image/Car.jpg";

function CarRental(){
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                 <h1>Car Rental</h1>
                 <p>Luxury and comfort can go hand in hand. You�ll experience this in the backseat of our cars. Chauffeur driven, reliable and spick and span, pick from our range of rental cars as per your need. From local and outstation travel to sightseeing, weddings, airport pick-ups and drops, we'll be at your service for any journey. And in case you get used to the pampering, you can also book our cars on a contract basis for an entire month.</p>
            </div>
            <div className={classes.right}>
               <img src={car} alt="Best hotel booking service"  />
            </div>
        </div>

    );
};
export default CarRental;