import classes from "./Allinternational.module.css";
import Navbar from "../../../NAVBAR/Navbar";
import Footer from "../../../FOOTER/Footer";
import { Link } from "react-router-dom";
import data from "./AllinternationalData.json";
import { useEffect } from "react";

const Allinternational=()=>{
    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);
    return(
        <>
        <Navbar />
        <h1 className={classes.heading}>All International Places</h1>
        <div className={classes.container}>
            { data && data.map(record=>{
                return(
                    <div className={classes.card} key={record.id}>
                    <img src={record.image} alt="best tours and travels agency"/>
                    <div className={classes.content}>
                    <h2>{record.name}</h2>
                    <p><b>Duration :</b> {record.duration}</p>
                    <p><b>Starting Price :</b> ₹ {record.price}</p>
                    <Link className={classes.enquiry} to="/contact">Enquire Now</Link>
                    </div>
                </div>
                )
            })}
            
        </div>
        <Footer />
        </>
    );
};
export default Allinternational;