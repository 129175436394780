import Navbar from "../NAVBAR/Navbar";
import BgSlider from "../HOME/HERO/BgSlider";
import Indian from "../HOME/Indian Destination/Indian";
import IndianCard from "../HOME/Indian Destination/IndianCard";
import International from "../HOME/International Destination/International";
import InternationalCard from "../HOME/International Destination/InternationalCard";
import Mdh from "../HOME/MDH Special/Mdh";
import MdhCard from "../HOME/MDH Special/MdhCard";
import Why from "../HOME/Why Us/Why";
import Testimonials from "../HOME/Testimonials/Testimonials";
import Footer from "../FOOTER/Footer";
import { useEffect } from "react";
import Header from "../NAVBAR/Header";
import ServiceCard from "../Services/ServiceCard";
import Watsapp from "../Watsapp/Watsapp";


function Home (){
  useEffect(()=>{
   window.scrollTo(0,0);
  },[]);
    return(
        <>
      <Header />
      <Navbar />
      <BgSlider />
      <Mdh />
      <MdhCard />
      <ServiceCard/>
      <Indian />
      <IndianCard/>
      <International />
      <InternationalCard />
      <Why />
      <Testimonials />
      <Watsapp />
      <Footer />
      </>
    );
};
export default Home;