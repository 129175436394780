import classes from "./Indian.module.css";
import next from "./Images/next.png";
import { Link } from "react-router-dom";

const Indian = ()=>{
    return(
        <>
        <div className={classes.wrap}>
        <div className={classes.container}>
            <h1>Trending <span>Domestic Packages</span></h1>
            <p>India is one of the most beautiful places in the world to travel. Our national tour packages will discover the unique culture of
               Kerala, Pink City of Jaipur, Mountainous region of Ladakh & more places for you.
            </p>
        </div>
        <div className={classes.icon}>
            <p>View All</p>
            <Link to="/domastic"><img src={next} alt="View more indian destinations" /></Link>
        </div>
        </div>
        </>
    );
};
export default Indian;