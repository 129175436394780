import classes from "./Connect.module.css";
import { Link } from "react-router-dom";

function Connect (){
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                <h2>Plan to Travel the World with</h2>
                <h1>My Dreams Holiday</h1>
            </div>
            <div className={classes.right}>
                <button><Link className={classes.btnlnk} to="/contact">Contact Us</Link></button>
            </div>
        </div>
    );
};
export default Connect;