import classes from "./Mission.module.css";

const Mission=()=>{
    return(
     <div className={classes.container}>
        <div className={classes.mission}>
            <h1>Our Vision</h1>
            <p>Our Vision is to become the leading top tour & travels provider in Pune. We want to develop and deliver an exciting tour & travels services to our customers. We also want to fulfil all our customer’s expectations, so they can get satisfaction from our services. “We believe customer satisfaction is our success”.</p>
        </div>
        <div className={classes.vision}>
            <h1>Our Mission</h1>
            <p>My Dreams Holiday mission is to provide a high quality of tourism services to our customer. Our some important mission are listed below:</p>
            <li>Providing Premium Service to customer</li>
            <li>Fulfill all our customer dream.</li>
            <li>Want to become the most valuable & trusted agency </li>
        </div>

     </div>
    );
};
export default Mission;