import classes from "./Customise.module.css";
import hotel from "./Image/39993.jpg";

function Hotelbooking(){
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                 <h1>Customized Tour Package</h1>
                 <p>At My Dreams Holidays, we believe that every traveler deserves a vacation that reflects their dreams. That is why we go above and beyond to understand your desires and create a customized tour package that surpasses your expectations.
                    With My Dreams Holidays, you have the power to create your own itinerary, perfectly suited to your preferences and interests.</p>
            </div>
            <div className={classes.right}>
               <img src={hotel} alt="Best hotel booking service"  />
            </div>
        </div>

    );
};
export default Hotelbooking;