import classes from "./Hotelbooking.module.css";
import hotel from "./Image/Hotel.jpg";

function Hotelbooking(){
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                 <h1>Hotel Booking</h1>
                 <p>Say goodbye to sleepless nights, as we’ll make sure your home away from home is nothing but delightful. Double-checked by us for quality and value for your money, each hotel we recommend will live up to your expectations. Carefully handpicked depending on your needs, we’ll offer you the perfect stay with a deal that’s as perfect.</p>
            </div>
            <div className={classes.right}>
               <img src={hotel} alt="Best hotel booking service"  />
            </div>
        </div>

    );
};
export default Hotelbooking;