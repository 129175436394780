import classes from "./Service1.module.css";

function Service1 (){
    return(
        <>
        <div className={classes.heading}>
            <h1>Our Services</h1>
        </div>
        </>
    );
};export default Service1;