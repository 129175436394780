import classes from "./Visa.module.css";
import visa from "./Image/visa.jpg";

function Visa(){
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                 <h1>Visa Services</h1>
                 <p>A visa is your passport to your dream destination, but the process to get it is a nightmare. Reason why we want to ease you out at this critical point. With Tourist Visa Services for all major countries, we’ll meticulously assist you through the entire process, making sure all your documents and paperwork are accurate. After all, the beginning of your journey should start off on a good note. </p>
            </div>
            <div className={classes.right}>
               <img src={visa} alt="Visa / Passport Service"  />
            </div>
        </div>

    );
};
export default Visa;