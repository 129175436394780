import classes from "./IndianCard.module.css";

import { Link } from "react-router-dom";

const IndianCard=()=>{
    return(
      <div className={classes.container}>
        
        <div className={classes.card1}>
           <h2>ANDAMAN</h2>
           <p>Starting Price</p>
           <h3>₹ 16900/-</h3>
            <button><Link to="/contact" className={classes.btnlnk}>Enquire Now</Link></button>
        </div>
        <div className={classes.card2}>
           <h2>KERALA</h2>
           <p>Starting Price</p>
           <h3>₹ 16900/-</h3>
            <button><Link to="/contact" className={classes.btnlnk}>Enquire Now</Link></button>
        </div>
        <div className={classes.card3}>
           <h2>GUJARAT</h2>
           <p>Starting Price</p>
           <h3>₹ 16900/-</h3>
            <button><Link to="/contact" className={classes.btnlnk}>Enquire Now</Link></button>
        </div>
        <div className={classes.card4}>
           <h2>HIMACHAL</h2>
           <p>Starting Price</p>
           <h3>₹ 16900/-</h3>
            <button><Link to="/contact" className={classes.btnlnk}>Enquire Now</Link></button>
        </div>
        
        
      </div>
    );
};
export default IndianCard;