import Navbar from "../NAVBAR/Navbar";
import Footer from "../FOOTER/Footer";
import ContactHead from "../Contact/ContactHead";
import ContactForm from "../Contact/ContactForm";
import { useEffect } from "react";
import Header from "../NAVBAR/Header";

function Contact (){
  useEffect(()=>{
   window.scrollTo(0,0)
  },[]);
    return(
      <>
      <Header />
      <Navbar/>
      <ContactHead />
      <ContactForm />
      <Footer />
      </>
      
    );
};
export default Contact;