import classes from "./Foregn.module.css";
import money from "./Image/money exange.jpg";

function Foregn(){
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                 <h1>Foreign Exchange</h1>
                 <p>Make your currency exchange simpler with My Dreams Holidays. We are one of the leading foreign currency exchange agents  that offer live rates to our customers. Visit your nearest branch to get your currency exchanged with us! 
                    With My Dreams Holidays, you can make your way to an easier, simpler and quicker way of exchanging currencies. As one of the top foreign currency exchange agents in Pune, we leave no stone unturned to make your transactions a hassle-free one. 
                 </p>
            </div>
            <div className={classes.right}>
               <img src={money} alt="Best foreign exchange service"  />
            </div>
        </div>

    );
};
export default Foregn;