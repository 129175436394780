import classes from "./Mdh.module.css";

const Mdh = ()=>{
    return(
        <>
        <div className={classes.container}>
            <h1>My Dreams Holiday <span>Specials</span></h1>
            
        </div>
        </>
    );
};
export default Mdh;