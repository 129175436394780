import "./Footer.css";
import insta from "./Images/instagram.png";
import fb from "./Images/facebook.png";
import linkdin from "./Images/linkedin.png";
import logo from "./Images/LOGO.png";
import heart from "./Images/heart.3da8786bcd0853d69489.gif";
import {Link} from "react-router-dom";


function Footer (){
    return(
    <footer>
      <div className="waves">
         <div className="wave" id="wave1"></div>
         <div className="wave" id="wave2"></div>
         <div className="wave" id="wave3"></div>
      </div> 
      <div className="Foot">
         <div className="col1">
            <img src={logo }alt="my dreams holiday best tour and travels"></img>
            <p>Stay tuned with our travel world through social media. Visit and let us know your reasons to celebrate life. !</p>
            <div className="icons">
                <Link to="https://www.facebook.com/mydreamsholiday/" target="_"><img src={fb} alt="my dreams holiday" /></Link>
                <Link to="https://www.instagram.com/mydreamsholidays2/" target="_"><img src={insta} alt="my dreams holiday" /></Link>
                <Link to="https://www.linkedin.com/in/my-dreams-holidays-5952b6298/" target="_"><img src={linkdin} alt="my dreams holiday" /></Link>
            </div>
         </div>
         <div className="col2">
            <h2>Quick Links</h2>
            <p><Link className='lnkbtn' to="/">Home</Link></p>
            <p><Link className="lnkbtn" to="/about">About Us</Link></p>
            <p><Link className="lnkbtn" to="/contact">Contact Us</Link></p>
         </div>
         <div className="col2">
         <h2>Explore</h2>
            <p><Link className="lnkbtn" to="/services">Services</Link></p>
            <p><Link className="lnkbtn" to="/">Blogs</Link></p>
            <p><Link className="lnkbtn" to="/">Privacy Policy</Link></p>
           </div>
           <div className="col2">
         <h2>Contact Us</h2>
            <p>Kutwalh Complex ground floor 04 patharwasti,Supe,Tal Baramati 412204</p>
            <p>+91 7030990011  | +91 9960415779</p>
            
           </div>
      </div>
      <div className="copy"><p>Copyright © 2023 My Dreams Holidays | Made with <span className="heart"><img src={heart} alt="MY DREAMS HOLIDAY" /></span> by CORTICA</p></div>
    </footer>
    );
};
export default Footer;