import classes from "./Why.module.css";
import image1 from "./Images/quality.png";
import image2 from "./Images/online-support.png";
import image3 from "./Images/airplane.png";
import image4 from "./Images/tag.png";

function Why (){
    return(
        <section className={classes.container}>
            <div className={classes.head}>
                <h1>Why Book With Us ?</h1>
                <p>We offer best airfares, special discounts and a seamless online ticket booking experience.</p>
            </div>
            <div className={classes.card}>
                <div className={classes.box}>
                    <div className={classes.image}><img src={image1}  alt="Best Fares"/></div>
                    <p>Assured Best Fares</p>
                </div>
                 <div className={classes.box}>
                 <div className={classes.image}><img src={image2}  alt="Best Fares"/></div>
                    <p>24x7 Customer Support</p>
                </div>
                 <div className={classes.box}>
                 <div className={classes.image}><img src={image3}  alt="Best Fares"/></div>
                    <p>One Stop for All Travel Services</p>
                </div>
                 <div className={classes.box}>
                 <div className={classes.image}><img src={image4}  alt="Best Fares"/></div>
                    <p>Instant Deals on Flights & Holidays</p>
                </div>
            </div>
        </section>

    );
};
export default Why;