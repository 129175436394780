import classes from "./International.module.css";
import next from "../Indian Destination/Images/next.png";
import { Link } from "react-router-dom";

const Indian = ()=>{
    return(
        <>
        <div className={classes.wrap}>
        <div className={classes.container}>
            <h1>Trending <span>International Tour Packages</span></h1>
            <p>Looking for the International Tour, then you are at the right place to book your memorable International holiday. We understand
how much International holiday will be special for you, so we have shortlisted some beautiful destinations.</p>
        </div>
        <div className={classes.icon}>
            <p>View All</p>
            <Link to="/international"><img src={next} alt="View more indian destinations" /></Link>
        </div>
        </div>
        </>
    );
};
export default Indian;