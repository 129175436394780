import classes from "./ContactForm.module.css";

import india from "./Images/india.png";
import address from "./Images/location.png";
import contact from "./Images/telephone.png";
import email from  "./Images/email.png";

import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';



function ContactForm(){
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_lde8jku', 'template_s8wq5ei', form.current, 'JrYeBcImvaIXNd07V')
        .then((result) => {
            alert("Message Sent Succesfully..! We will contact you soon ..")
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };
    return(
        <>
    <div className={classes.container}>
        <div className={classes.left}>
            <h1><span className={classes.blue}>CONTACT</span>  DETAILS</h1>
            <div className={classes.row}>
            <img src={india} alt="tours and travels service provider" />
            <h3>India - Pune</h3>
            </div>
            <div className={classes.row}>
            <img src={address} alt="tours and travels service provider" />
            <p>Kutwalh Complex ground floor 04 patharwasti,Supe,Tal Baramati 412204</p>
            </div>  
            <div className={classes.row}>
            <img src={contact} alt="tours and travels service provider" />
            <p>+91 7030990011  |  +91 9960415779</p>
            </div> 
            <div className={classes.row}>
            <img src={email} alt="tours and travels service provider" />
            <p>mydreamsholidays2@gmail.com</p>
            </div> 
        </div>
        
        <div className={classes.right}>
        <h1><span className={classes.blue}>SEND</span>  YOUR ENQUIRY</h1>
        <div className={classes.bg}>
            <form ref={form} onSubmit={sendEmail} className={classes.card}>
                <div className={classes.r1}>
                    <input type="text" placeholder="First Name" name="name" required />
                    <input type="text" placeholder="Last Name" name="Lname" required />
                </div>
                <div className={classes.r1}>
                    <input type="email" placeholder="Email Address" name="email" required />
                    <input type="number" placeholder="Contact Number" name="contact" required />
                </div>
                <select name="package">
                    <option >Select Your Travel Packages and Services</option>
                    <option>Domastic Package</option>
                    <option>International Package</option>
                    <option>Car and Bus Rental Service</option>
                    <option>Train and Flight Booking</option>
                    <option>Hotel Booking Service</option>
                    <option>Other</option>
                </select>
                <textarea placeholder="Write Message" name="message"/>
                <button  type="submit" value="Send">SEND</button>
            </form>
        </div>
        </div>
    </div>
    <div className={classes.map}>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15149.536106105958!2d74.36733478010274!3d18.329756542762553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc3056a8d38b739%3A0x93c2b06aef24c15a!2sGadade%20Vasti%20Supe%2C%20Baramati!5e0!3m2!1sen!2sin!4v1697179655502!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    </>
    );
};
export default ContactForm;