import classes from "./Domastic.module.css";
import international from "./Image/international.jpg";

function Domestic(){
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                 <h1>Domestic & International Ticketing</h1>
                 <p>Experience leads to perfection. And we’ve been perfecting the art of ticketing for decades now. Whether domestic or international, our expertise will assist you in getting only the best deals and prices on flights. Our years of knowledge will also help you pick smarter travel routes, so that you can spend less time travelling and more time enjoying.</p>
            </div>
            <div className={classes.right}>
               <img src={international} alt="Best hotel booking service"  />
            </div>
        </div>

    );
};
export default Domestic;