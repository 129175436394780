import classes from "./bg.module.css";


const Bg =(props)=>{
    return(
      <div className={classes.container}>
        <img src={props.image}></img>
      </div>
    );
};
export default Bg;