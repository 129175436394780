import classes from "./InternationalCard.module.css";
import { Link } from "react-router-dom";


function InternationalCard (){
    return(
        <div className={classes.container}>
        
        <div className={classes.card1}>
           <h2>SOUTH AFRICA</h2>
           <p>Starting Price</p>
           <h3>₹ 16900/-</h3>
           <button><Link to="/contact" className={classes.btnlnk}>Enquire Now</Link></button>
        </div>
        <div className={classes.card2}>
           <h2>VIETNAM</h2>
           <p>Starting Price</p>
           <h3>₹ 16900/-</h3>
           <button><Link to="/contact" className={classes.btnlnk}>Enquire Now</Link></button>
        </div>
        <div className={classes.card3}>
           <h2>THAILAND</h2>
           <p>Starting Price</p>
           <h3>₹ 16900/-</h3>
           <button><Link to="/contact" className={classes.btnlnk}>Enquire Now</Link></button>
        </div>
        <div className={classes.card4}>
           <h2>BALI</h2>
           <p>Starting Price</p>
           <h3>₹ 16900/-</h3>
           <button><Link to="/contact" className={classes.btnlnk}>Enquire Now</Link></button>
        </div>
        
        
      </div>
    );
};
export default InternationalCard;