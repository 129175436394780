import Navbar from "../NAVBAR/Navbar";
import Footer from "../FOOTER/Footer";
import Service1 from "../Services/Service1";
import Hotelbooking from "../Services/ServicePage/Hotelbooking";
import Domestic from "../Services/ServicePage/Domestic";
import Foregn from "../Services/ServicePage/Foregn";
import Visa from "../Services/ServicePage/Visa";
import CarRental from "../Services/ServicePage/CarRental";
import Connect from "../Services/ServicePage/Connect";
import { useEffect } from "react";
import Header from "../NAVBAR/Header";
import Customise from "../Services/ServicePage/Customise";

function Services (){
  useEffect(()=>{
  window.scrollTo(0,0)
  },[]);
    return(
      <>
      <Header />
      <Navbar/>
      <Service1 />
      <Customise />
      <Hotelbooking />
      <Domestic />
      <Foregn />
      <Visa />
      <CarRental />
      <Connect />
      <Footer />
      </>
    );
};
export default Services;