import Navbar from "../NAVBAR/Navbar";
import Footer from "../FOOTER/Footer";
import About1 from "../ABOUT/About1/About1";
import Mission from "../ABOUT/MissionVission/Mission";
import Banner from "../ABOUT/Banner/Banner";
import WhyDream from "../ABOUT/Why Dream/WhyDream";
import Header from "../NAVBAR/Header";
import { useEffect } from "react";

function About (){
  useEffect (()=>{
  window.scrollTo(0,0)
  },[]);
    return(
      <>
      <Header />
      <Navbar/>
      <About1 />
      <Banner />
      <WhyDream />
      <Mission />
      <Footer />
      </>
    );
};
export default About;