import React from "react";
import "./App.css";
import { BrowserRouter,Routes,Route } from "react-router-dom";
import Services from "./Components/Pages/Services";
import Contact from "./Components/Pages/Contact";
import About from "./Components/Pages/About";
import Home from "./Components/Pages/Home";
import AllIndian from "./Components/HOME/Indian Destination/ALL INDIAN/AllIndian";
import Allinternational from "./Components/HOME/International Destination/All International/Allinternational";
function App() {
  return (
    <div className="App">
       <BrowserRouter>
       <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/domastic" element={<AllIndian />} />
        <Route path="/international" element={<Allinternational />} />
        
       </Routes>
       </BrowserRouter>
    </div>
  );
}

export default App;
