import classes from "./Banner.module.css";

const Banner =()=>{
    return(
        <div className={classes.container}>
            <h1 className={classes.r1}>PLAN YOUR HOLIDAYS WITH</h1>
            <h1 className={classes.r2}>MY DREAMS HOLIDAYS</h1>
        </div>
    );
};
export default Banner;
