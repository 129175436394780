import classes from "./About1.module.css";
import image1 from "./Images/istockphoto-1318013849-612x612.jpg";

const About1 =()=>{
    return(
    <>
    <div className={classes.heading}>
        <h1>About Us</h1>
    </div>
    <div className={classes.container}>
        <div className={classes.left}>
            <h1>Introduction</h1>
            <p>My Dreams Holiday is one of the fastest-growing Domestic and International tours & travels agency in Pune. We offer a wide range of domestic and International tours destination to our customer. Our domestic tours packages cover majestic places like Kashmir, Kerala, Jaipur, Leh Laddakh, New Delhi, Agra, Rajasthan, Goa, and more. International Tour packages cover dynamic destination like Mauritius, Dubai, Maldives, Abu Dhabi, Malaysia, Singapore & Sri Lanka. We always work as per customer need, “we believe customer satisfaction is our success”.</p>
            <p>At "My Dreams Holiday," we believe that every journey holds the potential for transformative experiences. Whether you seek sun-soaked beach getaways, thrilling adventures in the rugged wilderness, or cultural explorations in exotic destinations, our extensive range of travel options is designed to cater to all your desires and aspirations.</p>
        </div>
        <div className={classes.right}><img src={image1} alt="travels and tour" /></div>
    </div>
    </>
    );
};
export default About1;