import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from '../NAVBAR/Images/logo- My Dreams Holidays.jpg';

export default function Whatsapp() {
    return (
        <div className="App">
            <FloatingWhatsApp
                phoneNumber="7030990011"
                accountName="My Dreams Holidays"
                avatar={logo}
                chatMessage="Hello, Welcome to My Dreams Holidays"
                allowEsc
                allowClickAway
                notification
                notificationSound
            />
        </div>
    );
}