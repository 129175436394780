import classes from "./ServiceCard.module.css";
import ticket from "./Image/ticket.png";
import holiday from "./Image/holiday.png";
import celebrity from "./Image/famous.png";
import ring from "./Image/rings.png";
import meeting from "./Image/meeting.png";
import calender from "./Image/calendar.png";
import visa from "./Image/passport.png";
import travelinsurence from "./Image/travel-insurance.png";
import booking from "./Image/using-phone.png";

const ServiceCard =()=>{
    return(
        <>
        <h1 className={classes.line}>Our <span>Services</span></h1>
      <div className={classes.container}>
        <div className={classes.card}>
            <img src={ticket} alt="online ticket booking" />
            <h2>Ticketing</h2>
            <p>Domestic and International tickets at best prices</p>
        </div>
        <div className={classes.card}>
            <img src={holiday} alt="online ticket booking" />
            <h2>Customized Holidays</h2>
            <p>You name it. We design it. At the most affordable rates</p>
        </div>
        <div className={classes.card}>
            <img src={celebrity} alt="online ticket booking" />
            <h2>Celebrity Tour Management</h2>
            <p>Hassle Free solutions from start to end</p>
        </div>
        <div className={classes.card}>
            <img src={ring} alt="online ticket booking" />
            <h2>Destination Wedding</h2>
            <p>Premium help with all your on-site personalized wedding needs</p>
        </div>
        <div className={classes.card}>
            <img src={meeting} alt="online ticket booking" />
            <h2>Corporate Conferences</h2>
            <p>Creating memorable events for your business strategies and let you be creative</p>
        </div>
        <div className={classes.card}>
            <img src={calender} alt="online ticket booking" />
            <h2>Event Management</h2>
            <p>Enjoy your momemts. Leave your Event management to us.</p>
        </div>
        <div className={classes.card}>
            <img src={visa} alt="online ticket booking" />
            <h2>Visas</h2>
            <p>Trained and experienced department of Travel Services Visa</p>
        </div>
        <div className={classes.card}>
            <img src={travelinsurence} alt="online ticket booking" />
            <h2>Travel Insurance</h2>
            <p>Covers risks during travel protect you and your family</p>
        </div>
        <div className={classes.card}>
            <img src={booking} alt="online ticket booking" />
            <h2>Cruise Bookings</h2>
            <p>Plan the Trip of a Lifetime Explore Unique Itineraries</p>
        </div>
      </div>
      </>
    );
};
export default ServiceCard;