import classes from "./WhyDream.module.css";
import img1 from "../Banner/Images/4009407.jpg";

function WhyDream (){
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                <img src={img1} alt="Tours and travels" />
            </div>
            <div className={classes.right}>
                <h1>Why Us ?</h1>
                <p>We believe that traveling is not just about ticking off famous landmarks; it's about immersing yourself in the local culture, discovering hidden gems, and forging a genuine connection with the places you visit. Our guided tours and excursions are led by knowledgeable local guides who are passionate about showcasing the wonders of their homeland.</p>
                <p>Your comfort and peace of mind are paramount to us. Rest assured that every aspect of your journey, from the moment you step foot in your chosen destination until you return home, will be meticulously planned and flawlessly executed.</p>
            </div>
        </div>

    );
};
export default WhyDream;